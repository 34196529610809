.card-offer {
    font-family: $Roboto;
    margin-right: 30px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);

    .offer-image {
        height: 300px;
        position: relative;

        .price {
            background-color:#2d7d90;
            width: 30%;
            color: white;
            font-size: 1.6em;
            padding: 7px 9pt;
            position: absolute;
            left: 0;
            bottom: 30px;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }

    .offer-image img {
        height: 300px;
        width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .offer-info {
        padding: 20px 30px 17px;

        .first-text h3 {
            font-size: 18px;
            font-weight: $weightMBold;
        }

        .second-text li {
            font-size: 14px;
            font-family: $Roboto;
        }

        .list-inline {
            padding-left: 0;
            list-style: none;
            margin-left: -5px;
        }

        .list-inline > li {
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.card-offer-about {
    //width: 388px;
    height: 270px;
    font-family: $Roboto;
    margin-right: 30px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
    margin: 0;

    img {
        height: 215px;
    }

    .info-card {
        padding: $paddingNormal;
    }
}
.card-grid {
    height: 270px;
    font-family: $Roboto;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    .grid-image {
        height: 200px;
        position: relative;

        .price {
            background-color: $blue;
            width: auto;
            color: white;
            font-size: 1.4em;
            padding: 4px 6pt;
            position: absolute;
            left: 0;
            bottom: 30px;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;

            .exrate {
                color: white;
            }
        }
    }

    .grid-image {
        height: 200px;
        width: 100%;
        position: relative; 
        overflow: hidden;
        img {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            transition: transform .5s ease;
            height: 200px;
            width: 100%;
        }
       
      
    }

    .grid-info {
        padding: 10px 16px 15px;

        .first-text h3 {
            font-size: 16px;
            font-weight: $weightMBold;
            color: #008209;
        }

        .second-text li {
            font-size: 13px;
            font-family: $Roboto;
        }

        .list-inline {
            padding-left: 0;
            list-style: none;
            margin-left: -5px;
        }

        .list-inline > li {
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &:hover {
        .grid-image img {
            transform: scale(1.2);
            
        }

        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
    }
}

.card-list {
    cursor: pointer;
    font-family: $Roboto;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;

    .list-image {
        height: 210px;
        width: 50%;
        position: relative;

        .price {
            background-color:rgb(125, 162, 171);
            width: 40%;
            color: white;
            font-size: 1.5em;
            padding: 4px 6pt;
            position: absolute;
            left: 0;
            bottom: 30px;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
             
            /*span .desc {
                font-size: 0.8em;
            }*/
        }
    }

    .list-image {
        height: 210px;
        width: 50%;
        position: relative; 
        overflow: hidden;

        img {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            transition: transform .5s ease;
            height: 210px;
            width: 100%;
        }
    }

    .list-info {
        padding: 23px 30px;

        .first-text h3 {
            font-size: 22px;
            font-weight: $weightMBold;
            color: #008209;
        }

        .ft-price {
            width: 100%;
        }

        .ft-price h2 {
            color: #008d9a;
            font-size: 1.7em;
        }

        .second-text li {
            font-size: 14px;
            font-family: $Roboto;
        }

        .list-inline {
            padding-left: 0;
            list-style: none;
            margin-left: -5px;
        }

        .list-inline > li {
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &:hover {
        .list-image img {
            
            
                transform: scale(1.2);
            
        }

        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);
    }
}

.card-cart-gral {
    //border-bottom: 1px solid;
    width: 90%;
    border-bottom: 1px solid #b3b3b3a1;
    margin-bottom: 16px;
    
    .delete-icon {
        cursor: pointer;
        transition: color .5s;
        &:hover {
            color: $green;
        }
    }


    .data-tour {
        padding-left: $paddingHuge;
    }

    .price {
        width: 45%;
    }
    

    .cardcart-img {
        width: 45%;
        img {
            width: 93%;
        }
    }

    .cart-product {
        padding-left: $paddingHuge;
        .label {
            max-width: 50%;
        }
        .data {
            max-width: 50%;
        }
    }
    
    .cardcart-price {
        width: 50%;
        margin: 20px 0;
        //background-color: antiquewhite;
    }
}

.card-reservation {
    padding: 16px 0;
    border-bottom: 1px solid #7da2ab;
}

@media screen and (max-width: 1150px) {
    .third-text {
        .truncate-ellipsis {
            width: 345px;
        }
    }
}

@media screen and (max-width: 992px) {
    .third-text {
        .truncate-ellipsis {
            width: 245px;
        }
    }
}

@media screen and (max-width: 768px) {
    .card-list {
        .list-info {
            padding: 5px 20px;

            .first-text h3 {
                font-size: 20px;
            }

            .ft-price {
                width: 100%;
                justify-content: left;
                padding: 10px 0;
            }

            .white-space-24 {
                height: 0;
            }
        }

        .third-text {
            .truncate-ellipsis {
                width: 300px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .card-list {
        .third-text {
            .truncate-ellipsis {
                width: 215px;
            }
        }
    }
}