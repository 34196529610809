.pagination {
    /* Pagination links */
    .page a {
        color: black;
        float: left;
        padding: 8px 13px;
        text-decoration: none;
        transition: background-color .3s;
        border: 1px solid #ddd;
        margin: 0 4px;
        border-radius: 50px;
        background: white;
    }

    .current-page a {
        border-color: $blue;
        font-weight: 700;
        color: $blue;
    }

    .page a:hover:not(.active) {
        color: white;
        background-color: $blue;
        cursor: pointer;
    }
}