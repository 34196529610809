/**
*   colors.scss
*   @description: Variables de colores
*/


$black:             #000000;
$blackOverlay:      rgba(0, 0, 0, 0.6);
$white:             #FFFFFF;
$pink:              #ec4193;
$purple:            #7633fd;
$lighPurple:        #7633fda2;
$red:               #CF000F;
$gray:              #b3b3b3;
$darkGray:          #8f8f8f;
$lightGray:         #f1f1f1;
$darkgreen: #2a5456;

$blue:  rgb(0, 196, 221);
$green: rgb(0, 171, 18);
$yellow:#FFC800;
$darkpink: #b9015a;
/* Classes de colores */

.color-white {
    color: $white;
}

.color-black {
    color: $black;
}

.color-pink {
    color: $pink;
}

.color-purple {
    color: $purple;
}

.color-gray {
    color: $gray;
}

.color-darkgray {
    color: $darkGray;
}

.color-red {
    color: $red;
}

.color-o-red {
    color: red;
}

.color-green {
    color: $green;
}

.color-darkgreen {
    color: $darkgreen;
}

.color-blue {
    color: $blue;
}

.color-yellow {
    color: $yellow;
}

.color-l-yellow {
    color: #ffeb3b;
}

.bg-blue {
    background-color: $blue;
}

.bg-pink {
    background-color: $pink;
}