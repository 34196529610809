html {
    //overflow-y: scroll;
    scroll-behavior: smooth;
    //scroll-snap-type: y mandatory;
}

.cart {
    position: relative;
    background-color: $white;
    min-height: 100vh;

    .fb_reset {
        display: none;
    }

    .check-reservation-total {
        width: 55%;
        .label {
            padding-right: 22px;
        }

        .final-price {
            width: 66%;
            
            .exrate {
                color: $pink;
            }

            span {
                padding-right: 14px;
                font-family: $Roboto;
            }
        }
    }

    .section-cart {
        min-height: 400px;

        .titulo {
            display: none;
        }

        .taps{ 
            width: 60%;

            .steps {
                padding: 4px 0;
                .step-circle {
                    background: $gray;
                    padding: 5px 11px;;
                    border-radius: 50%;
                    font-size: 1.4rem;
                    margin-right: 8px;
                }
                
                .step-active {
                    background: $green;
                    padding: 5px 11px;;
                    border-radius: 50%;
                    font-size: 1.4rem;
                    margin-right: 8px;
                }

                &:hover {
                    .step-circle {
                        background-color: $green;
                    }
                }
            }
        }

        .cart-order {
            .carrito {
                min-height: 200px;
            }
        }

        .check-reservation, .make-reservation {
            position: sticky;
            bottom: 0;
            background-color: white;
            padding: 30px;
        }

        .confirmation {
            padding: 30px;
            position: sticky;
            bottom: 0;
            background-color: white;
        }
    }

    .reservation-box {
        position: relative;
        width: 80%;
        background-color: #e2e1df87;
        border: 1px solid #9e9e9e4d;
        border-radius: 4px;
        padding: 16px 30px 16px 40px;

        .reservation-text p {
            font-weight: 600;
            font-size: 1.1em;
        }

        .reservation-button {
            width: 30%;

            .btn-pay {
                width: 75%;
            }
        }
    }

    .shopping-cart {

        .tittle {
            color: $pink;
        }
        .header-cart {
            padding: $paddingMedium 0;
            background-color: #f7f7f7d4;
        }
        .item-cart {
            padding: $paddingNormal 0;
            background-color: #f7f7f7d4;
            position: relative;
            margin-bottom: 16px;

            .remove-icon {
                position: absolute;
                right: 8px;
                top: 8px;
                transition: color 300ms;
                &:hover {
                    color: $yellow;
                }
            }
            .divider {
                width: 15%;
                height: 15px;
                border-bottom: 2px solid $blue;
                margin-right: 41px;
            }

            .last-price {
                width: 78%;
                padding-right: 50px;
            }

            .exrate {
                font-size: 0.8rem;
                color: $pink;
            }
        }
        .tour-column {
            max-width: 265px;
            img {
               /* width: 55%;
                height: 106px;*/   
                width: 70%;
                height: 110px;
            }
        }
    }
    
}

svg {
    width: 100px;
    display: block;
    margin: auto;
}

.checkpay {
    min-height: 442px;
    align-items: center;
    background-color: white;
}

.check-success {
    .language, .exchange-rate, .cart-nav {
        display: none;
    }
}

@media screen and (max-width: 992px) {
	.cart { 
        .item-cart {
            flex-direction: column;
            margin-bottom: $marginSemi;
            align-items: center;

            .divider {
                margin-right: 5% !important;
            }
        }

        .header-cart {
            display: none;
        }

        .check-reservation {
            flex-direction: column-reverse;
            //padding: 0px !important;

            span {
                text-align: end;
                margin-top: $marginSemi;
                margin-bottom: $marginSemi;
            }

        }

        

        .check-reservation-total {
            width: 100%;

            .final-price  {
                width: 48%;
            }

            .label {
                padding: 0;
            }
        }

        
    }
}


@media screen and (max-width: 768px) {

    .cart {

        .make-reservation {
            padding: 30px 0 !important;
        }
        
        .check-reservation-total {
            width: 100%;

            .final-price  {
                width: 100%;
            }
           
        }

        .section-cart {
            .taps {
                width: 90%;
            }
        } 
    }
}

@media screen and (max-width: 576px) {
    .cart {
        .section-cart {
            .titulo {
                display: flex;
                padding-top: 24px;
                
                h4 {
                    color: $green;
                }
            }

            .taps{ 
                width: 60%;

                .steps h2{
                    display: none;
                }
            }

            .cart-order {
                .check-reservation {
                    padding: 24px 0;

                    .check-reservation-buttons {
                        .btn-back, .btn-cart {
                            font-size: 1em;
                        }
                    }
                }
            }

            .make-reservation {
                .back .btn-back {
                    font-size: 1em;
                } 

                .continue .btn-cart {
                    font-size: 1em;
                    width: auto;
                }
            }

            .confirmation {
                .back .btn-back {
                    font-size: 1em;
                }

                .continue .btn-pay {
                    font-size: 1em;
                    width: auto;
                }
            }
        }

        .shopping-cart {
            .item-cart {
                .input-cart {
                    width: 80%;
                }

                .last-price {
                    padding-right: 10px;
                }
            }
        }
    }
}

.swal-text {
    font-family: $Roboto;
}