.modal {
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  width: 80%;
  margin: auto;
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}