.terms-conditions {
    p {
        line-height: 24px;
    }

    .text-terms {
        ul {
            padding-left: 30px;
            list-style: disc;
            font-family: "Roboto", sans-serif;
            font-size: 0.93rem;

            li {
                line-height: 24px;
            }
        }
    }
}