button {
    font-family: $Roboto;
    background-color: transparent;
}

.btn {
    cursor: pointer;
    padding: $paddingNormal $paddingMedium;
    -webkit-tap-highlight-color: transparent;
}

.btn-buy {
    background-color: $blue;
    border-radius: 18px;
    font-size: $fontSmall;
    color: white;
    width: 80%;
    transition: background-color  .5s;
    &:hover {
        background-color: #2a9db9;
    }
}

.btn-disabled-buy {
    background-color: #b4e0ea;
    border-radius: 18px;
    font-size: $fontSmall;
    color: white;
    width: 80%;
    transition: background-color  .5s;
    &:hover {
        background-color: #b4e0ea;
    }
}

.btn-cart {
    width: 35%;
    background-color: $green;
    border-radius: 5px;
    font-size: 1.2em;
    color: white;
    transition: background-color  .5s;
    /*&:first-child {
        margin-right: 85px;
    }*/
    &:hover {
        background-color: $darkgreen;
    }
}

.btn-pay {
    width: 45%;
}

.btn-back {
    //width: 35%;
    background-color: $blue;
    border-radius: 5px;
    font-size: 1.2em;
    color: white;
    transition: background-color  .5s;
    /*&:first-child {
        margin-right: 85px;
    }*/
    &:hover {
        background-color: $darkgreen;
    }
}

.btn-pay {
    width: 35%;
    background-color: #e68e20;
    border-radius: 5px;
    font-size: 1.1em;
    color: white;
    transition: background-color  .5s;
    &:hover {
        background-color: $darkgreen;
    }
}

.btn-black {
    background-color: black;
    //width: 10%;
    border-radius: 5px;
    font-size: 1.1em;
    color: white;
    transition: background-color  .5s;
    &:hover {
        background-color: $darkgreen;
    }
}

.btn-send {
    background-color: #2d7d90;
    width: 100%;
    border-radius: 5px;
    font-size: 1.1em;
    color: white;
    transition: background-color  .5s;
    &:hover {
        background-color: $darkgreen;
    }
}

.btn-pink {
    width: 35%;
    background-color: $pink;
    border-radius: 5px;
    font-size: 1.2em;
    color: white;
    transition: background-color  .5s;
    /*&:first-child {
        margin-right: 85px;
    }*/
    &:hover {
        background-color: $pink;
    }
}

.btn-filter {
    width: 100%;
    background-color: $pink;
    border-radius: 50px;
    font-size: 1.1em;
    color: white;
    transition: background-color  .5s;
    &:hover {
        background-color: $darkpink;
    }
}

.btn-reservation {
    box-shadow: 0px 0px 6px 3px #eee;
    width: 16%;
    background-color: rgb(220, 2, 2);
    border-radius: 5px;
    font-size: 1.1em;
    color: white;
    cursor: pointer;
    transition: background-color .5s;

    &:hover {
        background-color: #ef2121;
    }
}

.btn-banner {
  box-shadow: 0 0 6px 4px;
  width: 25%;
  border-radius: 4px;
  padding: 10px 24px;
  font-size: 1.2em;
  font-family: 'Raleway';
}

.btn-login {
    width: 100%;
    background-color: $blue;
    border-radius: 5px;
    font-size: 1.2em;
    color: white;
    transition: background-color  .5s;
    padding: 8px 0;
}