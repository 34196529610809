/* Slider WEB */

/*.owl-carousel {
    z-index: 0;
    margin-right: 10px;

    .owl-stage {
        width: auto !important;
    }

    .owl-nav {
        position: absolute;
        top: 41%;
    }

    .owl-prev {
        margin-left: 15px;
    }

    .owl-next {
        margin-right: 15px;
        justify-content: flex-end;
    }

    .owl-item {
        padding-right: 5px;
    }
}

.owl-stage-outer {
    height: 360px;
}

.owl-dots {
    height: 30px;
    align-items: center;
    justify-content: center;

    .owl-dot {
        width: 8px;
        height: 8px;
        background: black;
        border-radius: 50%;
        margin: 8px;
    }

    .active {
        background: #2d7d90;
    }
}

.fa-5x {
    font-size: 3em;
    color: #c11623;
    font-weight: 600;
    width: 7%;
    align-items: center;
    text-align: center;
    background-color: #ffffffbd;
    border-radius: 50%;
}

@media screen and (max-width: 1292px) {
    .owl-stage-outer {
        height: 255px;
    }

    .owl-carousel {
        .owl-nav {
            top: 39%;
        }
    }

    .fa-5x {
        font-size: 2.5em;
        width: 7.5%;
    }
}

@media screen and (max-width: 992px) {
    .owl-stage-outer {
        height: 240px;
    }

    .owl-carousel {
        .owl-nav {
            top: 38%;
        }
    }

    .fa-5x {
        font-size: 2.5em;
        width: 10%;
    }
}

@media screen and (max-width: 768px) {
    .owl-stage-outer {
        height: 215px;
    }

    .owl-carousel {
        .owl-nav {
            top: 38%;
        }
    }

    .owl-dots {
        .owl-dot {
            width: 7px;
            height: 7px;
        }
    }

    .fa-5x {
        font-size: 2em;
        width: 11%;
    }
}

@media screen and (max-width: 576px) {
    .owl-stage-outer {
        height: 125px;
    }

    .owl-carousel {
        .owl-nav {
            top: 35%;
        }

        .owl-prev {
            margin-left: 8px;
        }

        .owl-next {
            margin-right: 8px;
        }
    }

    .owl-dots {
        height: 25px;

        .owl-dot {
            width: 6px;
            height: 6px;
        }
    }

    .fa-5x {
        font-size: 1.5em;
        width: 12%;
    }
}*/

/* Slider WEB 

.owl-carousel {
    z-index: 0;
    margin-right: 10px;

    .owl-nav {
        position: absolute;
        top: 45%;
    }

    .owl-prev {
        margin-left: 15px;
    }

    .owl-next {
        margin-right: 15px;
        justify-content: flex-end;
    }

    .owl-item {
        padding-right: 5px;
    }
}

.owl-stage-outer {
    height: 600px;
}

.fa-5x {
    font-size: 5em;
    color: white;
}*/

.top-of-page {
    .top-imagen {
        position: absolute;
        //height: 350px;
    }

    .top-imagen img {
        width: inherit;
        object-fit: cover;
    }

    .rd-parallax-layer {
        transform: translate3d(0px, -1px, 0px);

        .bg-overlay-darker {
            background-color: rgba(0, 0, 0, 0.3);
            //height: 350px;

            .top-text h1 {
                color: white;
                padding-top: 20px;
                font-size: 2.8em;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .top-of-page {
        .top-imagen {
            height: 280px !important;

            img {
                object-fit: cover;
            }
        }

        .rd-parallax-layer {
            .bg-overlay-darker {
                height: 280px !important;

                .top-text h1 {
                    font-size: 2.3em;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .top-of-page {
        .top-imagen {
            height: 245px !important;
            overflow: hidden;

            img {
                object-fit: cover;
            }
        }

        .rd-parallax-layer {
            .bg-overlay-darker {
                height: 245px !important;

                .top-text h1 {
                    font-size: 1.6em;
                }
            }
        }
    }
}