.reservation {
    .reservation-sections {
        background-color: #f7f7f7;
        border-radius: 4px;
        
        .reservation-left {
            width: 100%;
            padding: 30px;
            //background-color: beige;

            .traveler-data {
                padding-bottom: 10px;

                p {
                    font-size: 16px;
                }
            }
        }

        .reservation-right {
            padding: 30px;
            //background-color: #2a545614;

            .reservation-detail {
                overflow: auto;
            }

            .reservation-detail-title {
                border-bottom: 1px solid #7da2ab;
                padding-bottom: 16px;
            }

            .reservation-detail-cart {
                /*max-height: 440px;
                height: auto;
                overflow: auto;*/

                .exrate {
                    color: $pink;
                    font-size: 0.8rem;
                }

                .price {
                    width: 40%;
                    padding-right: 15px;
                }
            }

            .reservation-detail-total {
                .total-price {
                    width: 60%;
                    margin-right: 5%;
                    justify-content: flex-end;

                    .exrate {
                        color: $pink;
                        font-size: 1rem;
                    }
                }

                .total-price h3 {
                    color: $green;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .reservation {

        .reservation-detail-cart {
            .exrate {
                color: $pink;
                font-size: 0.8rem;
            }

            .price {
                width: 100% !important;
                padding-right: 15px;
                .row {
                    justify-content: flex-end;
                    align-items: center;
                    margin-top: $paddingNormal;
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .reservation {
        .container {
            width: 95%;
        }

        .data {
            h3 {
                font-size: $fontTiny;
            }
        }

        .reservation-detail-title {
            h3 {
                font-size: $fontRegular;
            }
        }

        .price {
            padding-right: 0px;
            h3 {
               font-size: $fontRegular;
            }
        }

        .total-title {
            padding-right: $paddingNormal;
        }

        .reservation-sections {
            .reservation-left {
                width: 100%; 
                padding: 30px 30px 0px 30px;
            }
        }
    }
}