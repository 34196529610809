/**
*   header.scss
*   @description: Estilos para los Headers
*/

/* Headers WEB */

.header-web {
	background-image: linear-gradient(to right, #FFFFFF 15%, #45d4e6 160%);
	//background-color: #fffffff5;
	//padding: 10px 0;
	z-index: 3;
    position: sticky;
	top: 0;
	font-family: $Roboto;
	border-bottom: 1px solid /*rgba(gray, 0.5)*/#7da2ab6b;

	.left {
		width: 30%;
		height: 90px;
		
		.logo{
			height: 60px;
			cursor: pointer;
		}	
	}

	.right {
		//width: 60%;

		.right-left {
			.nav-item {
				padding: 0 18px;
				a { 
					color: #4c4c4c;
					transition: color .5s;
					font-size: 1.1em;
					&:hover {
						color: $pink;
					}
				}
			}

			.whats-provider {
				//padding: 0 18px;
				width: 28%;

				p {
					padding-left: 12px;
					font-size: 1.1em;
					color: #4c4c4c;
					transition: color .5s;
					cursor: pointer;

					&:hover {
						color: $pink;
					}
				}

				i {
					padding-left: 20px;
					font-size: 1.7em;
					color: $green;
					cursor: pointer;
				}

				a {
					color: #4c4c4c;
					transition: color .5s;
					font-size: 1.1em;
					&:hover {
						color: $pink;
					}
				}	
			} 
		}

		.right-right {
			width: 45%;

			.exchange-rate {
				padding: 0 $paddingSemi;
				cursor: pointer;
				label { color: #4c4c4c; }
				//width: auto;
			}

			.exchange-rate label {
					cursor: pointer;
				}

			.exchange-rate i {
				font-size: 22px;
				padding-right: 6px;
			}

			.w3-bar-item {
				padding: 10px;
				font-weight: 600;
				width: 100%;

				i {				
					font-size: 22px;
					padding-top: 6px;
				}
			}

			.img-icon {
				height: 45px;
			}

			.img-icon-car {
				height: 32px;;
			}
			.cart-nav {
				position: relative;
				width: auto;

				.numbercart {
					position: absolute;
					bottom: 27px;
					left: 22px;
					border-radius: 13px;
					height: 22px;
					color: $green;
					background-color: /*#ffcb00*/white;
					width: 50%;
					font-size: 12px;
				}
			}

			.language {
				padding-right: 15px;
			}

			.language i {
				font-size: 22px;
				cursor: pointer;
			}

			#language {
				border-radius: 4px;
				height: 22px;
				padding-left: 6px;
				color: #4c4c4c;
				cursor: pointer;
				background-color: transparent;
			}

			.user {
				padding-left: 10px;
				color: #4c4c4c;
				font-weight: 500;
				cursor: pointer;

				/*&:hover {
					color: $pink;
				}*/

				i {
					color: $pink;
				}

				.circle-user {
					//padding: 8px 12px;
					width: 36px;
					height: 36px;
					background-color: $green;
					border-radius: 50%;
					border: 1px solid black;
					cursor: pointer;

					h5 {
						color: white;
						font-weight: 600;
						font-size: 1.1rem;
					}
				}
			}

			.login {
                padding-left: 16px;
				cursor: pointer;

                i {
                    font-size: 22px;
                }

				/*&:hover {
					.login-box {
						display: block;
					}
				}*/

				.login-box {
					display: none;
					position: absolute;
					top: 90px;
					width: 24%;
					background-color: white;
					border-radius: 4px;
					//padding: 24px;
					right: 26px;

					&::before {
						content: '';
						width: 0;
						height: 0;
						border: 20px solid #FFF;
						border-top: 0 solid rgba(0, 0, 0, 0)!important;
						border-right: 13px solid rgba(0, 0, 0, 0)!important;
						border-left: 13px solid rgba(0, 0, 0, 0);
						left: 80.6%;
						top: -5.3%;
						position: absolute;
					}

					.box {
						.box-body {
							padding: 0 24px 24px 24px;

							.passw, .usuar {
								position: relative;

								i {
									font-size: 1rem !important;
								}
							}

							.cuadrito-user, .cuadrito-pass {
								position: absolute;
								top: 9px;
								width: auto;
								padding: 0 8px;
							}

							/* Inputs */
							.input-container {
								position: relative;

								.ipt {
									outline: none;
									z-index: 1;
									position: relative;
									background: none;
									width: 100%;
									height: 60px;
									border: 0;
									color: #212121;
									font-size: 24px;
									font-weight: 400;

								&:focus {
									~ .lbl {
										color: #9d9d9d;
										transform: translate(-12%, -50%) scale(0.75);
									}

									~ .bar {
										&::before,
										&::after {
											width: 50%;
										}
									}
								}

								&:valid {
									~ .lbl {
										color: #9d9d9d;
										transform: translate(-12%, -50%) scale(0.75);
									}
								}
								}

								.lbl {
									position: absolute;
									top: 0;
									left: 0;
									color: #757575;
									font-size: 24px;
									font-weight: 300;
									line-height: 60px;
									transition: 0.2s ease;
								}

								.bar {
									position: absolute;
									left: 0;
									bottom: 0;
									background: #757575;
									width: 100%;
									height: 1px;

									&::before,
									&::after {
										content: '';
										position: absolute;
										background: #ed2553;
										width: 0;
										height: 2px;
										transition: .2s ease;
									}

									&::before {
										left: 50%;
									}

									&::after {
										right: 50%;
									}
								}
							}

							/* Input */
							.input-container {
							input {
								color: $white;

								&:focus {
								~ label {
									color: $white;
								}

								~ .bar {
									&:before,
									&:after {
									background: $white;
									}
								}
								}

								&:valid {
								~ label {
									color: $white;
								}
								}
							}

							label {
								color: rgba($white, 0.8);
							}

							.bar {
								background: rgba($white, 0.8);
							}
							}
						}

						.login-h3 {
							position: relative;
							z-index: 1;
							border-left: 5px solid #ed2553;
							margin: 0 0 35px;
							padding: 10px 0 10px 50px;
							color: #ed2553;
							font-size: 22px;
							font-weight: 600;
							text-transform: uppercase;
							/*position: relative;
							width: 100%;*/
						}

						/*.login-h3::after, .login-h3::before {
							background-color: #777;
							content: "";
							height: 1px;
							position: absolute;
							top: 12px;
							width: 110px;
						}

						.login-h3::before {
							left: 0;
							background-image: -webkit-linear-gradient(right, #00c4dd, #fff);
						}

						.login-h3::after {
							right: 0;
							background-image: -webkit-linear-gradient(left, #00c4dd, #fff);
						}*/
					}
				}

				.login-wrap {
					//display: none;
					position: absolute;
					top: 90px;
					width: 24%;
					background-color: white;
					border-radius: 4px;
					padding: 2rem;
					right: 26px;
					border: 1px solid #7da2ab6b;

					h3 {
						color: #4c4c4c;
					}

					&::before {
						content: '';
						width: 0;
						height: 0;
						border: 20px solid #FFF;
						border-top: 0 solid rgba(0, 0, 0, 0)!important;
						border-right: 13px solid rgba(0, 0, 0, 0)!important;
						border-left: 13px solid rgba(0, 0, 0, 0);
						left: 79.6%;
						top: -5.3%;
						position: absolute;
					}

					.icon {
						width: 80px;
						height: 80px;
						background: $blue;
						border-radius: 50%;
						font-size: 30px;
						margin: 0 auto;
						margin-bottom: 10px;

						i {
							color: #fff;
							font-size: 1.6rem;
						}

						.fa-user-o::before {
							content: "\f2c0";
						}
					}

					.btn.btn-primary {
						background: $blue !important;
						border: 1px solid $blue !important;
						color: #fff!important;
						font-size: 1rem;
					}

					.close-x {
						display: none;
					}
				}
            }
		}
	}

	/*.carrito {
		width: auto;
		padding-right: 10px;
	}

	.carrito i {
		font-size: 20px;
	}

	.language {
		width: auto;
	}
	
	h3 {
		color: /*rgb(11, 76, 92) white;
	}

	#language {
		padding-left: 10px;
		margin-left: 10px;
		border-radius: 11px;
		height: 25px;
	}

	.w3-bar-item {
		padding: 10px;
		font-weight: 600;
		width: 100%;

		i {
			color: black;
			font-size: 22px 24px;
			padding-top: 6px;
		}
	}

	.select-language {
		width: auto;
		padding-right: 10px;
	}

	.cart-nav {
		position: relative;
		width: auto;
		//padding-left: 15px;

		.numbercart {
			position: absolute;
			bottom: /*22px24px;
			left: /*40px26px;
			background-color: black;
			border-radius: 13px;
			height: 26px;
			color: #d9e6cd;
			width: /*45%59%;
			border: 1px solid #d9e6cd;
			font-size: 14px;
		}
	}

	.select-language {
		position: static;
		cursor: pointer;

		.idioma .globe {
			padding: 0 8px 0 15px;
			width: 56%;
		}

		/*&:hover {
			.language-dropdown-content {
				display: flex;
			}
		}

		.language-dropdown-content {
			position: absolute;
			z-index: 1;
			padding: 0;
			margin: 0;
			//min-width: 20%;
			display: none;
			background-color: #d9e6cdbd;
			top: /*57px18px;
			right: 114px;
			max-width: 12%;
			color: black;

			.w3-button {
				width: 100%;
				&:hover {
					background-color: black;
					color: #d9e6cd;
				}
			}
		}
	}*/
}

@media screen and (min-width: 2690px) {
	.header-web {
		.login-wrap {
			right: 328px !important;
			width: 16% !important;

			&::before {
				left: 16.2% !important;
			}
		}
	}
}

@media screen and (min-width: 2020px) and (max-width: 2689px) {
	.header-web {
		.login-wrap {
			right: 48px !important;

			&::before {
				left: 34.2% !important;
			}
		}
	}
}

@media screen and (min-width: 1798px) and (max-width: 2019px) {
	.header-web {
		.login-wrap {
			&::before {
				left: 47.6% !important;
			}
		}
	}
}

@media screen and (min-width: 1686px) and (max-width: 1797px) {
	.header-web {
		.login-wrap {
			&::before {
				left: 57.6% !important;
			}
		}
	}
}

@media screen and (min-width: 1498px) and (max-width: 1685px) {
	.header-web {
		.login-wrap {
			&::before {
				left: 70.6% !important;
			}
		}
	}
}

@media screen and (max-width: 992px) {
	.header-web {
		.left {
			.logo {
				height: 42px;
			}
		}

		.right {
			width: 100%;

			.right-left {
				.nav-item {
					padding: 0 10px;
				}

				.whats-provider p {
					display: none;
				}

				.activities, .contacto, .us, .private-tour, .xcaret {
					display: none;
				}
			}

			.right-right {
				width: 50%;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.header-web {
		.header-info {
			padding: 6px 0;
		}

		.left {
			    width: 40%;
   				height: 50px;
			.logo{
				height: 30px;
			}
		}
		

		.right {
			width: 100%;
			justify-content: center;

			.right-left {
				justify-content: center;
				padding: 8px 0;
				//display: none;
				.activities, .contacto, .us, .private-tour, .xcaret {
					display: none;
				}

				.whats-provider {
					width: 100% !important;

					.justify-center {
						justify-content: flex-end;
					}
				}
			}

			.right-right {
				justify-content: flex-end;
				width: 95%;

				.exchange-rate {
					//padding: 0 16px 0 0;

					i {
						font-size: 16px;
    					padding-right: 6px;
					}

					label {
    					font-size: .8rem;
					}
				}

				.language {
					padding: 0;

					i {
						font-size: 16px;
    					padding-right: 6px;
					}

					select {
    					font-size: .8rem;
					}
				}

				.w3-bar-item i {
					font-size: 16px;
					padding-top: 6px;
				}

				.cart-nav
				{
					.numbercart {
						position: absolute;
						bottom: 22px;
						left: 22px;
						border-radius: 13px;
						height: 18px;
						color: white;
						background-color: #ffcb00;
						width: 50%;
						font-size: 14px;
					}

				} 
			}
		}

		.login-wrap {
			width: 40% !important;
			top: 76px !important;
    		right: 20px !important;

			&::before {
				left: 86.6% !important;
    			top: -6.3% !important;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.header-web {
		.logo{
			height: 30px;
		}

		h3 {
			font-size: 1rem;
		}

		.right {
			.right-left {
				.whats-provider {
					i {
						font-size: 1.4em;
					}
				}
			}

			width: 100%;

			.nav-item {
				padding: 0 12px;
			}
		}

		/*.user {
			p {
				display: none !important;
			}
		}*/

		.login {
			i {
				font-size: 1.1em !important;
			}
		}

		.login-wrap {
			position: absolute;
			top: 0 !important;
			width: 100% !important;
			height: 100vh;
			justify-content: center;
			background-color: #fffefef5 !important;
			border-radius: 4px;
			padding: 2rem;
			right: 0 !important;
    		border: 1px solid #7da2ab6b;

			.icon {
				width: 100px !important;
				height: 100px !important;

				i {
					font-size: 2rem !important;
				}
			} 

			h3 {
				font-size: 1.5rem;
			}

			.login-form {
				padding-top: 16px;
			}

			.close-x {
				position: absolute;
				display: block !important;
				top: 14px;
				right: 24px;
				width: auto;

				p {
					font-weight: 500;
					font-size: 0.9rem;
				}
			}
		}
	}
}

/*header .header-top {
	padding: 16px 0;
	background-color: #F4F5F6;
}*/

@media screen and (max-width: 320px) {
	.principal .home .offers-gral .catalog-home .container .catalog-cards-grid .card-grid {
		height: 260px !important;
		width: 300px !important;
	}
}