
.flex {
    display: flex;
}

/*.flex div, .flex img, .flex header, .flex nav, .flex form {
    display: flex;
    box-sizing: border-box;
}*/

/* Slider WEB */

.owl-carousel {
    z-index: 0;
    //margin-right: 10px;  

    .owl-item img {
        width: 100%;
    }
}

.best-offers {
    .container-slide {
        .owl-nav {
            position: absolute;
            top: 45%;
            left: 0;
        }
    
        .owl-prev {
            margin-left: 12px;
        }
    
        .owl-next {
            margin-right: 8px;
            justify-content: flex-end;
        }

        .owl-item {
            padding-right: 5px;
            //margin: $marginLarge $marginNormal;   
        }
    }

    .top-imagen {
        .owl-stage-outer {
            height: auto;
        }
    }
}

/*.owl-dots {
    height: 40px;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;

    .owl-dot {
        width: 8px;
        height: 8px;
        background: black;
        border-radius: 50%;
        margin: 8px;
    }

    .active {
        background: rgb(11, 76, 92);
    }
}*/

.fa-angle-left {
    padding-left: 9px;
}

.fa-angle-right {
    padding-left: 15px;
}

.fa-5x {
    font-size: 3em;
    color: #00BCD4/*#2d7d90;
    font-weight: 600;
    width: 48px;
    height: 48px;
    align-items: center;
    /*background-color: #ffffffbd;
    border-radius: 50%;*/
}

@media screen and (max-width: 1292px) {
    .container-slide {
        /*.owl-stage-outer {
            height: 255px;
        }*/
    
        .owl-carousel {
            .owl-nav {
                //top: 39%;
                top: 47%;
            }
        }
    }

    .top-imagen {
        .owl-stage-outer {
            height: auto;
        }
    }

    .fa-5x {
        font-size: 2.5em;
        width: 7.5%;
    }
}

@media screen and (max-width: 992px) {
    .container-slide {
        /*.owl-stage-outer {
            height: 240px;
        }*/
    
        .owl-carousel {
            .owl-nav {
                //top: 38%;
                top: 45%;
            }
        }
    }

    .top-imagen {
        .owl-stage-outer {
            height: auto;
        }
    }

    .fa-5x {
        font-size: 2.5em;
        width: 10%;
    }
}

@media screen and (max-width: 768px) {
    .container-slide{
        /*.owl-stage-outer {
            height: 215px;
        }*/
    
        .owl-carousel {
            .owl-nav {
                top: 45%;
            }
        }
    }

    .top-imagen {
        .owl-stage-outer {
            height: auto;
        }
    }

    .owl-dots {
        .owl-dot {
            width: 7px;
            height: 7px;
        }
    }

    .fa-5x {
        font-size: 2em;
        width: 11%;
        padding: 7px;
        background-color: transparent;
    }
}

@media screen and (max-width: 576px) {
    .slid {
        overflow: hidden;

        /*.owl-carousel {
            .owl-nav {
                top: 6%;
            }
        }

        .fa-5x {
            color: white;
        }*/

        .owl-stage-outer {
            height: 200px;
    
            .owl-stage {
                 .owl-item {
                    margin: 0;
                    height: auto;
                    padding-right: 0;
                 }
            }
        }
    }

    .top-imagen {
        .owl-stage-outer {
            height: auto;
        }
    }

    .container-slide {
        .white-space-48 {
            height: 24px;
        }
        .owl-carousel {
            margin-right: 0;
    
            .owl-nav {
                top: 42%;
            }
    
            .owl-prev {
                margin-left: 8px;
            }
    
            .owl-next {
                margin-right: 8px;
            }
        }
    }

    /*.owl-dots {
        height: 25px;

        .owl-dot {
            width: 6px;
            height: 6px;
        }
    }*/

    .fa-5x {
        font-size: 1.5em;
        width: 12%;
    }
}