.contact {
    min-height: 100vh;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;

    .content {
        .content-contact {
            width: 50%;
            background: #9e9e9e4a;
            border-radius: 4px;
        }
    }

    /*footer {
        position: absolute;
        bottom: 0;
    }*/
}

@media screen and (max-width: 1204px) {
    .contact {
        .content {
            .content-contact {
                width: 60%;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .contact {
        .content {
            .content-contact {
                width: 70%; 
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .contact {
        .content {
            .content-contact {
                width: 75%;

                .btn-pink {
                    font-size: 1.1em;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .contact {
        .content {
            .content-contact {
                width: 85%;

                input, textarea {
                    font-size: 0.7em;
                }

                p {
                    font-size: 0.9em;
                }

                .btn-pink {
                    font-size: 1em;
                }
            }
        }
    }
}