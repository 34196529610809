input, textarea, select, option, label {
    font-family: $Roboto;
}

textarea {
    resize: none;
    height: 70px;
}

.input {
    width: 100%;
    padding: 4px 12px;
    font-size: 14px;
    border: 1px solid #7da2ab;
    border-radius: .25rem;
}

.input-cart {
    width: 40%;
}

.input-left {
    margin-right: 10px; 
    &:focus {
        border: 1px solid $blue;
    }    
    /*width: 100%;
    padding: 4px 12px;
    font-size: 14px;
    border: 1px solid #7da2ab;
    border-radius: .25rem;*/
}

.input-right {
    margin-left: 10px; 
    /*width: 100%;
    padding: 4px 12px;
    font-size: 14px;
    border: 1px solid #7da2ab;
    border-radius: .25rem;*/
}

.input-pay {
    width: 100%;
    padding: 6px 12px;
    font-size: 13px;
    border: 1px solid #7da2ab;
    border-radius: .25rem;
}

.input-search {
    width: 100%;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 16px;
    background: #e8e8e8;
}

.input-range__slider {
    margin-top: -0.45rem;
}
.inputGroup {
    background-color: #fff;
    display: block;
    margin: 2px 0;
    position: relative;

    label {
      padding: 8px 8px 8px 40px;
      width: 100%;
      display: block;
      text-align: left;
      color: rgb(65, 65, 65);
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #e0e0e0c4;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 22px;
        height: 22px;
        content: '';
        border: 2px solid rgb(202, 202, 202);
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      color: rgb(0, 0, 0);

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        background-color: #008209;
        border-color: #008209;
      }
    }

    input {
      width: 25px;
      height: 15px;
      order: 1;
      z-index: 2;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
}

.truncate-ellipsis {
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-login {
  border-bottom: 2px solid $pink;
  width: 100%;
  padding: 8px 32px;
  font-size: 16px;
  //border-radius: .2rem;
}

.form-control {
  background: #fff;
  color: #000;
  font-size: 14px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0,0,0,.1);
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: .375rem .75rem;

  &:focus, &:active {
    outline: none!important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid $blue;
}
}