.product-detail {
    position: relative;

    .card-tour {
        background-color: $white;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .description {
        padding: $paddingMedium;
    }

    .section-left {
        width: 60%;

        .principal-img {
            background: white;
            height: auto;
            //max-height: 400px;
            border-top: 6px solid $blue;
            img {
                //width: 100%;
                object-fit: cover;
            }
        }

        .gallery {
            display: grid !important;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 0.5rem;
        }

        .gallery-img img {
            height: 132px;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }

        .gallery-img {
            position: relative;

            .rd-parallax-gallery {
                /*opacity: 0;
                transition: .3s all ease;*/
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: 300ms all ease;
                //visibility: hidden;
                opacity: 0;
                //transform: translate(-50%, 100%);

                .top-text {
                    cursor: pointer;
                }

                .top-text i {
                    color: white;
                    font-size: 2.3em;
                    cursor: pointer;
                }
            }

            &:hover> .rd-parallax-gallery {
                background: linear-gradient(to bottom, transparent 5%, rgba(black, 0.8));
                //visibility: visible;
                opacity: 1;
            }
        }

        .pdf a i {
            padding-right: 5px;
            font-size: 1.1em;
        }

        .info-card {
            background-color: white;
            padding: 16px 24px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        }
        .include-or-not {
           
            .include {
                padding-right: 20px;

                .include-text ul {
                    padding-left: 30px;
                    list-style: square;
                    font-family: "Roboto", sans-serif;
                    font-size: 0.93rem;
                }
            }

            .no-include {
                .include-text ul {
                    padding-left: 30px;
                    list-style: square;
                    font-family: "Roboto", sans-serif;
                    font-size: 0.93rem;
                }
            }
        }

        .tab {
            overflow: hidden;

            button {
                width: 100%;
                border-bottom: 1px $green solid;
                outline: none;
                cursor: pointer;
                padding: 14px 16px;
                transition: 0.3s;
                font-size: 17px;
            }

            /*button:hover {
                background-color: #ddd;
            }*/

            .active {
                border: 1px $green solid;
                color: $green;
                font-weight: 500;
                border-bottom: 0 none;
            }
        }

        .box-tab {
            .recomendations {
                border: 0 none !important;
                padding: 18px 20px;

                .recomendations-text ul {
                    padding-left: 30px;
                    list-style: square;
                    font-family: "Roboto", sans-serif;
                    font-size: 0.93rem;
                }
            } 
            
            .no-include {
                padding: 18px 20px;
                
                .include-text ul {
                    padding-left: 30px;
                    list-style: square;
                    font-family: "Roboto", sans-serif;
                    font-size: 0.93rem;
                }
            }
        }

        .recomendations {
            padding-top: 20px;
            border-top: 1px solid #ccccccd9;
        }

        .link {
            padding-top: 20px;
            border-top: 1px solid #ccccccd9;

            .link-text a{
                color: $blue;
                text-decoration: underline;
            }
        }
    }
    
    .section-right {
        align-self: flex-start;
        width: 40%;
        margin-left: 75px;
        position: sticky;
        top: /*120px*/95px;
        right: 70px;

        .info {
            background-color: $white;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            padding: 16px ;
            .price strike {
                font-size: 12px;
                color: darkred;
            }

            .save p {
                color: red;
            }
        }

        .reservation {
            align-self: flex-start;
            /*position: sticky;
            top: 80px;
            right: 70px;
            width: 40%;
            margin-left: 75px;*/
            background-color: $white;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            .reservation-title {
                background-color: /*#7da2ab*/ $pink;
                padding: 10px 20px;
                border-bottom: 1px solid /*#7da2ab*/ $pink;
                box-shadow: 0 0 1px rgba(0,0,0,0.5);
            }

            .reservation-title h4 {
                color: white;
            }

            .reservation-data {
                padding: 20px;

                .reservation-prices {
                    .sub-adults {
                        padding-right: 0px !important;
                    }

                    .sub-children p {
                        font-size: 0.9em;
                        //color: $green;
                    }
                }

                .sub-adults {
                    padding-right: 10px;
                }

                .sub-children {
                    width: auto;
                }
            }
        }
    }

    .fix {
        position: fixed;
        bottom: 0;
    }
}

@media screen and (max-width: 992px) {
    .product-detail {
        .section-right {
            margin-left: $marginLarge;
            right: 35px;
        }
    }
}

@media screen and (max-width: 768px) {
    .product-detail {
        .section-left {
            width: 100%;
        }

        .section-right {
            width: 100%;
            margin-left: 0;

            .adults .input-left {
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .product-detail {
        .section-left {
            width: 100%;

            .tab {
                button {
                    padding: 8px 8px;
                    font-size: 17px;
                }
            }
        }

        .section-right {
            width: 100%;
        }

        .no-result {
            h2 {
                font-size: 1.4rem;
            }
        }
    }
}