.principal {
    position: relative;
    background-color: white;

    .home {
        display: flex;

        .links-main {
            display: none;
        }

        .slid {
            overflow: hidden;
        }
        
        .header-web {
            background-image: linear-gradient(to right, #FFFFFF 15%, #45d4e6 160%);
            position: sticky;
            top: 0;

            .right .exchange-rate label {
                cursor: pointer;
                font-size: 1.1em;
            }

            .nav-item a {
                font-size: 1.1em;
            }

            .whats-provider {
                //padding: 0 18px;
                width: 14%;

                a {
                    font-size: 1.1em;

                    &:hover {
						color: $pink;
					}
                }
            }

            #language {
                font-size: 1.1em;            
            }

            /*.w3-bar-item {
                display: none;
            }*/

            .user {
				padding-left: 10px;

				.circle-user {
					//padding: 8px 12px;
					width: 36px;
					height: 36px;
					background-color: $green;
					border-radius: 50%;
					border: 1px solid black;
					cursor: pointer;

					h5 {
						color: white;
						font-weight: 600;
						font-size: 1.1rem;
					}
				}

                .login {
                    padding-left: 16px;

                    i {
                        font-size: 22px;
                    }

                    .login-box {
                        display: none;
                        position: absolute;
                        top: 90px;
                        width: 24%;
                        background-color: white;
                        border-radius: 4px;
                        //padding: 24px;
                        right: 26px;

                        &::before {
                            content: '';
                            width: 0;
                            height: 0;
                            border: 20px solid #FFF;
                            border-top: 0 solid rgba(0, 0, 0, 0)!important;
                            border-right: 13px solid rgba(0, 0, 0, 0)!important;
                            border-left: 13px solid rgba(0, 0, 0, 0);
                            left: 80.6%;
                            top: -5.3%;
                            position: absolute;
                        }

                        .box {
                            .box-body {
                                padding: 0 24px 24px 24px;

                                .passw, .usuar {
                                    i {
                                        font-size: 1rem;
                                    }
                                }

                                .passw, .usuar {
                                    position: relative;
                                }

                                .cuadrito-user, .cuadrito-pass {
                                    position: absolute;
                                    top: 9px;
                                    width: auto;
                                    padding: 0 8px;
                                }

                                /* Inputs */
                                .input-container {
                                    position: relative;

                                    .ipt {
                                        outline: none;
                                        z-index: 1;
                                        position: relative;
                                        background: none;
                                        width: 100%;
                                        height: 60px;
                                        border: 0;
                                        color: #212121;
                                        font-size: 24px;
                                        font-weight: 400;

                                    &:focus {
                                        ~ .lbl {
                                            color: #9d9d9d;
                                            transform: translate(-12%, -50%) scale(0.75);
                                        }

                                        ~ .bar {
                                            &::before,
                                            &::after {
                                                width: 50%;
                                            }
                                        }
                                    }

                                    &:valid {
                                        ~ .lbl {
                                            color: #9d9d9d;
                                            transform: translate(-12%, -50%) scale(0.75);
                                        }
                                    }
                                    }

                                    .lbl {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        color: #757575;
                                        font-size: 24px;
                                        font-weight: 300;
                                        line-height: 60px;
                                        transition: 0.2s ease;
                                    }

                                    .bar {
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                        background: #757575;
                                        width: 100%;
                                        height: 1px;

                                        &::before,
                                        &::after {
                                            content: '';
                                            position: absolute;
                                            background: #ed2553;
                                            width: 0;
                                            height: 2px;
                                            transition: .2s ease;
                                        }

                                        &::before {
                                            left: 50%;
                                        }

                                        &::after {
                                            right: 50%;
                                        }
                                    }
                                }

                                /* Input */
                                .input-container {
                                input {
                                    color: $white;

                                    &:focus {
                                    ~ label {
                                        color: $white;
                                    }

                                    ~ .bar {
                                        &:before,
                                        &:after {
                                        background: $white;
                                        }
                                    }
                                    }

                                    &:valid {
                                    ~ label {
                                        color: $white;
                                    }
                                    }
                                }

                                label {
                                    color: rgba($white, 0.8);
                                }

                                .bar {
                                    background: rgba($white, 0.8);
                                }
                                }
                            }

                            .login-h3 {
                                position: relative;
                                z-index: 1;
                                border-left: 5px solid #ed2553;
                                margin: 0 0 35px;
                                padding: 10px 0 10px 50px;
                                color: #ed2553;
                                font-size: 22px;
                                font-weight: 600;
                                text-transform: uppercase;
                                /*position: relative;
                                width: 100%;*/
                            }

                            /*.login-h3::after, .login-h3::before {
                                background-color: #777;
                                content: "";
                                height: 1px;
                                position: absolute;
                                top: 12px;
                                width: 110px;
                            }

                            .login-h3::before {
                                left: 0;
                                background-image: -webkit-linear-gradient(right, #00c4dd, #fff);
                            }

                            .login-h3::after {
                                right: 0;
                                background-image: -webkit-linear-gradient(left, #00c4dd, #fff);
                            }*/
                        }
                    }
                }
			}
        }

        .video-container {
            position: relative;
            bottom: 0%;
            left: 0%;
            width: 100%;
            overflow: hidden;
          //  height: 600px;
        }
        
        .video {
          //  position: absolute;
            z-index: 0;
            width: 100%;
            //height: auto;
            height: 100%;
        }

        .categories-menu {
			position: relative;

			&.full-franja {
				background-color: $pink;
			}
            //height: 50px;

            .menu-cat {
                overflow: hidden;

                a {
                    padding: 16px 32px;
                    cursor: pointer;
                    color: white;
                    font-size: 1em;

                    &:hover {
                        background-color: $blue;
                        //font-size: 1.1em;
                        //font-weight: 600;
                    }
                }

                .icon {
                    display: none;
                }
            }
        }

        /*.offers {
            background-color: $white;
        }*/

        .categories {
            background-color: black;
            color: #f1f1f1;
            z-index: 2;
            position: sticky;
            top: 0;

            .w3-bar {
                overflow: hidden;
                width: 100%;

                .w3-bar-item {
                    padding: 14px;
                    font-weight: 600;
                    width: 100%;

                    i {
                        color: white;
                        font-size: 22px;
                    }
                }

                .categories-left {
                    .categorie-item:hover {
                        background-color: #cad9dc;
                        color: black;
                        cursor: pointer;
                    }

                    .more {
                        cursor: pointer;
                        position: static;
                        font-weight: 600;
                        padding: 14px;

                        .more-button span {
                            padding-right: 5px;
                        }

                        .showMore {
                            position: absolute;
                            z-index: 1;
                            padding: 0;
                            margin: 0;
                            min-width: 10%;
                            background-color: black;
                            top: 46px;
                            max-width: 16.5%;

                            .categorie-item {
                                width: 100%;
                                &:hover {
                                    background-color: #cad9dc;
                                    color: black;
                                }
                            }
                        }

                        .more-categories {
                            position: absolute;
                            z-index: 1;
                            padding: 0;
                            margin: 0;
                            min-width: 10%;
                            display: none;
                            background-color: black;
                            top: 51px;
                            max-width: 15.5%;

                            .categorie-item {
                                width: 100%;
                                &:hover {
                                    background-color: #cad9dc;
                                    color: black;
                                }
                            }
                        }
                    }
                }

                .categories-right {
                    width: 30%;
                    padding-right: 40px;

                    .select-language, .cart-nav {
                        width: auto;
                        padding-right: 10px;
                    }

                    .select-language {
                        position: static;
                        cursor: pointer;

                        .idioma .globe {
                            padding-right: 10px;
                        }

                        &:hover {
                            .language-dropdown-content {
                                display: flex;
                            }
                        }

                        .language-dropdown-content {
                            position: absolute;
                            z-index: 1;
                            padding: 0;
                            margin: 0;
                            min-width: 20%;
                            display: none;
                            background-color: black;
                            top: 51px;
                            max-width: 20%;

                            .w3-button {
                                width: 100%;
                                &:hover {
                                    background-color: #cad9dc;
                                    color: black;
                                }
                            }
                        }
                    }
                }
            }
        }

        .card-category {
            cursor: pointer;
            position: relative;

            .card-60, .card-60-320 {
                &:hover {
                    box-shadow: 0 1px 3px 0 #ffffff;
                }
                width: 60%;
                height: 330px;
                position: relative;
                margin-right: $paddingNormal;
                .image {
                    width: 100%;
                    height: 100%;
                    img { 
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

                 
            .card-40, .card-40-320 {
                &:hover {
                    box-shadow: 0 1px 3px 0 #ffffff;
                }
                width: 40%;
                height: 330px;
                position: relative;
                margin-left: $paddingNormal;
                .image {
                    width: 100%;
                    height: 100%;
                    img {                    
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }

        .card-cont {
        .column-image {
                        
            figure {
             position: relative; 
            overflow: hidden;
            margin: 0;
            height: 100%;
            width: 100%;
            filter: grayscale(0);

           
            }
            figure img{
            width: 100%;
            height: 100%;
            transition: transform .5s ease;
            }
            figure::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                display: block;
                content: '';
                width: 0;
                height: 0;
                background: rgba(0,0,0,.2);
                border-radius: 100%;
                transform: translate(-50%, -50%);
                opacity: 0;
            }
           
         /*   figure:hover::before {
                animation: circle .75s forwards;
            }
*/
        
            @keyframes bloom {
                0% {
                    filter: grayscale(0);
                }
                40% {
                    filter: grayscale(.3);
                }
                100% {
                    filter: grayscale(.4);
                }
            }
            @keyframes circle {
                0% {
                opacity: .5;
                background: rgba(255, 177, 8, 0.349);
                
                }
                40% {
                opacity: 1;
                background: rgba(255, 238, 2, 0.63);
                }
                100% {
                    width: 200%;
                    height: 200%;
                    opacity: 0;
                }
            }
        }

        .title {
            left: 0;
            bottom: 0;
            width: 100%;
            position: absolute;
            background: linear-gradient(to top, rgba(black, 0.6) 0%, transparent 100%);
            transition: background 300ms ease-in-out;
            a, p {
                padding: 12px 16px;
                font-size: 2rem;
                line-height: normal;
            }
        }

        &:hover{
                .column-image {
                
                    img {
                        transform: scale(1.2);
                    }
                    figure{
                        animation: bloom ease-in-out .65s forwards;
                        
                    }
                    
                }

                
            }
        }

        .invert {
            flex-direction: row-reverse;
            .card-40-320 {
                margin-left: 0;
                margin-right: 8px;
                height: 330px;
            }
            .card-60-320 {
                margin-right: 0;
                margin-left: 8px;
                height: 330px;
            }
        }

        .third {
            .card-100 {
                &:hover {
                    box-shadow: 0 1px 3px 0 #ffffff;
                }
                width: 100%;
                height: 330px;
                position: relative;
                .image {
                    width: 100%;
                    height: 100%;
                    img {                    
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
             
            }
        }

        .offers-gral {
            background-image: url("/images/playa3.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            .best-offers {
                //padding: 0 0 50px 0;
                position: relative;
                background-size: cover;
                //background-color: $white;
                /* background-image: url("http://demo.frothyteam.com/casia-preview/casia/img/home1/package-bg.jpg");*/
                //background-image: url("/images/playa3.jpg");
                .container {
                    overflow: hidden;

                    }
                }

                .packs {
                    //height: 450px;

                    /*.owl-item {
                        margin: 24px 8px;
                    }*/

                    .item-promotion {
                        position: relative;

                        .rd-parallax-layer {
                            height: 100%;
                            transform: translate3d(0px, -1px, 0px);
                            position: absolute;

                            .bg-overlay-darker {
                                background-color: rgba(0, 0, 0, 0.1);
                                cursor: pointer;

                                .top-text {
                                    //padding: 0 0 48px 0;
                                    padding: 24px 32px 0 0;
                                }
                            }
                        }
                    }

                    .item-promotion img {
                        //height: 100%;
                        background-size: cover;
                        height: 400px;
                    }

                    /*&:hover {
                        .item-promotion {
                            position: relative;

                            .rd-parallax-layer {
                                height: 100%;
                                transform: translate3d(0px, -1px, 0px);
                                position: absolute;

                                .bg-overlay-darker {
                                    background-color: rgba(0, 0, 0, 0.1);
                                    cursor: pointer;

                                    .top-text {
                                        padding: 0 0 48px 0;
                                    }
                                }
                            }
                        }
                    }*/
                }
                
                .item {
                    position: relative;
                    cursor: pointer;
                    transition: all 400ms ease-in-out;
                // box-shadow: 0 1px 10px 0 rgba(0,0,0,.75);
                    box-shadow: 0 1px 3px 0 #cccccce8;
                    border-radius: 0px;
                    /*box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);*/

                    .image {
                        width: 100%;
                        height: 300px;
                        position: relative;
                        overflow: hidden; 

                        img {
                            top: 0;
                            width: 100%;
                            height: 300px;
                            object-fit: cover;
                            object-position: center;
                            border-radius: 0;
                            transition: transform .5s ease;
                        }
                    }

                    .overlay {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        width: 100%;
                        opacity: 0;
                        transition: .5s ease;
                    }
                    
                    .info {
                        height: 140px;
                        padding: $paddingMedium;
                        background-color: $white;
                        border-radius: 0;
                        z-index: 1;  
                        border-bottom: 5px solid $white;
                        transition: border .5s ease;

                        .title h3 {
                            color: #00c4dd;
                        }
                        
                        h4{
                            font-size: 1rem;
                            font-weight: 200;
                        }

                        .btn-view {
                            padding: 8px 12px;
                            font-size: 0.8rem;
                            border-radius: 16px;
                            font-weight: $weightSemi;
                            border: 1px solid $green;
                        }

                        .price {
                            .price-number {
                                color: $pink;
                                font-size: 1.6rem;
                            }
                        }
                        
                    }

                    .offer {
                        position: absolute;
                        width: 22%;
                    }

                    &:hover {
                        .image {
                            
                            img {
                                transform: scale(1.2);
                            }
                        }

                        .overlay {
                            opacity: 1;
                        }

                        .info {
                            border-bottom: 5px solid $yellow;
                        }
                    }
                }

            .catalog-home {
                //padding-bottom: 64px;

                .container {
                    width: 85%;

                    .catalog-cards-grid {
                        display: grid;
                        //padding-top: 1.7rem;
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: 1.2rem;
                        justify-items: center;

                        .card-grid {
                            height: 320px;
                            width: 369px;

                            .grid-image {
                                height: 225px;

                                img {
                                    height: 225px;
                                }
                            }
                        } 

                        .ribbon-wrapper-red {
                            width: 120px;
                            height: 95px;
                            overflow: hidden;
                            position: absolute;
                            top: -4px;
                            right: -4px;
                        }
                        
                        .ribbon-red {
                            font: bold 15px Sans-Serif;
                            color: #fff;
                            text-align: center;
                            text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            position: relative;
                            padding: 5px 33px;
                            left: 22px;
                            top: 20px;
                            width: 127px;
                            height: 29px;
                            background-color: #ea181e;
                            background-image: linear-gradient(to top, rgb(158, 6, 6) , red,);
                            background-image: -webkit-linear-gradient(top, #ea5b18, #b90005);
                            background-image: -moz-linear-gradient(top, #BFDC7A, #8EBF45);
                            background-image: -ms-linear-gradient(top, #BFDC7A, #8EBF45);
                            background-image: -o-linear-gradient(top, #BFDC7A, #8EBF45);
                            color: #fff;
                            -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
                            -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
                            box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
                        }
                        
                        .ribbon-red:before,
                        .ribbon-red:after{
                            content: "";
                            border-top: 3px solid #b90005;   
                            border-left: 3px solid transparent;
                            border-right: 3px solid transparent;
                            position:absolute;
                            bottom: -3px;
                        }
                        
                        .ribbon-red:before{
                            left: 0;
                        }
                        
                        .ribbon-red:after{
                            right: 0;
                        }
                    }
                }
            }
        }

        .section-80 {
            background-position: center;
            /*background-color: #f5f5f5;*/

            .shell {
                margin-right: auto;
                margin-left: auto;
                padding-left: 15px;
                padding-right: 15px;

                .off-title h3 {
                    font-size: 36px;
                    font-weight: $weightRegular;
                }

                .offset-top-10 p {
                    font-size: 16px;
                    color: #2a5456;
                }
            }
        }

        .cards {
            width: 100%;
            display: grid !important;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 25px;
        }
    }

    .chat {
        position: sticky;
        bottom: 0;
        height: 0;

        .chat-scd {
            position: relative;
            bottom: 90px;
            padding-right: 30px;

            .chat-btn {
                //#0b4c5c
                background-color: #ec4193e6;
                border-radius: 50%;
                padding: 15px;

                i {
                    font-size: 40px;
                    color: white;
                }
            }

            .chat-popup {
                .popup {
                    padding-right: 20px;
                }

                .form-container {
                    width: 26%;
                    background-color: #f7f7f7;
                    border-radius: 4px;
                    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.1);
                    position: relative;

                    .head {
                        background-color: $pink;
                        padding: $paddingMedium 10px;
                        h3 { color: $white; }
                        border-radius: 4px 4px 0 0;
                    }

                    textarea {
                        height: 90px;
                    }

                    label {
                        font-size: 15px;
                    }

                    .close {
                        top: 18px;
                        right: 16px;
                        position: absolute;                        
                    }

                    .close a {
                        color: $white;
                        font-weight: 600;
                        font-size: 1.2rem;
                    }

                    .fc-inputs {
                        padding: 0 16px;
                        input:focus, textarea:focus {
                            border: 1px solid $blue;
                        }
                    }
                    .btn-send {
                        border-radius: 0;
                        font-weight: $weightSemi;
                        background-color: $pink;
                    }
                }
            }

            #myForm {
                display: none;
                position: absolute;
                bottom: 0;
            }

            #myFormConversation {
                position: absolute;
                bottom: 0;
            }

            .conversation-popup {
                .popup {
                    padding-right: 30px;

                    .form-container {
                        background-color: #f1f1f1;
                        width: 25%;
                        border: 1px solid $pink;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                        box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.1);

                        .fc-title {
                            padding: 10px 15px;
                            background-color: $pink;
                            color: white;
                            font-weight: 600;
                            border-top-left-radius: 4px;
                            border-top-right-radius: 4px;
                        }

                        .fc-inputs {
                            height: 250px;
                            overflow: auto;

                            .content-chat {
                                min-height: 250px;
                            }

                            .message-me p{
                                max-width: 90%;
                                border-radius: 8px;
                                padding: 16px 8px;
                                background-color: #ec41934a;
                            }

                            .message-user p {
                                max-width: 90%;
                                border-radius: 8px;
                                padding: 16px 8px;
                                background-color: hsla(0,0%,73.3%,.4);
                                margin: 4px 0;
                            }
                        }

                        .fc-button {
                            bottom: 0;
                            z-index: 1;
                            padding: 12px 0;
                            //position: fixed;
                            background-color: #fff;
                            box-shadow: 0 -0.1px 5px 0 #d9d9d9;

                            .container-controls {
                                width: 100%;
                                padding-left: 16px;

                                .left {
                                    width: 90%;

                                    input {
                                        border: 1px solid $pink;
                                    }
                                }

                                .right {
                                    .btn-little-send {
                                        cursor: pointer;
                                        color: $pink;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1292px) {
    .principal {
        .chat .chat-scd .chat-popup .popup {
            .form-container {
                width: 35%;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .principal {
        .chat .chat-scd .chat-popup .popup {
            .form-container {
                width: 40%;
            }
        }

        .home {
            .video-container {
                height: auto;
                .video {
                    position: relative;
                    z-index: 0;
                    bottom: 0;
                    width: 100%;
                    height: auto;
                }
            }

            .links-main {
                display: flex;
                padding: 12px 0;
                background-color: white;
                cursor: pointer;
                .nav-item {
                    transition: color 300ms;
                    text-decoration: underline;
                    color: $green;
                    span {
                        font-size: 1.1em;
                    } 
                    &:hover {
                        color: $pink;
                    }
                }

                .first {
                    padding-right: 80px;
                }

                .second {
                    width: 50%;
                }
            }  

            .offers-gral {
                .best-offers {
                    .container .packs {
                        //height: 350px;

                        .item-promotion img {
                            height: 290px !important;
                        }

                        /*.item-promotion .rd-parallax-layer .bg-overlay-darker .top-text {
                            padding: 20px 20px 0 0;

                            .btn-reservation {
                                width: 15%;
                                font-size: 1em;

                                p {
                                    display: none;
                                }
                            }
                        }*/
                    }
                }

                .catalog-home .container {
                    .catalog-cards-grid {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .principal {

        .chat .chat-scd .chat-popup .popup {
            .form-container {
                width: 50%;
            }
        }

        .home {

            .main-offer {
                .white-space-48 {
                    height: 16px;
                }
            }

            .links-main {
                display: flex;
                padding: 12px 0;
                background-color: white;
                cursor: pointer;
                .nav-item {
                    transition: color 300ms;
                    text-decoration: underline;
                    color: $green;
                    span {
                        font-size: 1.1em;
                    } 
                    &:hover {
                        color: $pink;
                    }
                }

                .first {
                   padding-right: 60px;
                } 
            }       

            .invert {
                .card-40-320 , .card-60-320 {
                    height: 345px;
                }
            }

            .categories-menu {
                .container {
                    width: 100%;
                }

                .menu-cat h4 {
                    font-size: 1rem;
                }

                /*.menu-cat {
                    .icon {
                        width: 100%;
                        display: block;
                    }
                }*/

                .none {
                    a {
                        display: none;
                    }

                    .icon {
                        width: 100%;
                        display: block;
                    }
                }

                .responsive {
                    position: relative;
                    display: block;

                    a {
                        display: block;
                        text-align: left;
                        padding: 12px 32px;
                    }

                    .icon {
                        width: 100%;
                        display: block;
                        text-align: center;
                    }
                }
            }
    
            .third {
                .card-100 {                   
                    height: 245px;                 
                }
            }
            .card-category {
    
                .card-60, .card-60-320 {                  
                    height: 245px;         
                               
                }    
                     
                .card-40, .card-40-320 {                  
                    height: 245px;                    
                }
            }

            .offers-gral {
                .best-offers {
                    .container .packs {
                        //height: 290px;

                        .item-promotion {
                            img {
                                height: 240px !important;
                            }

                            .rd-parallax-layer .bg-overlay-darker .top-text {
                                padding: 20px 20px 0 0;

                                .btn-reservation {
                                    width: 15%;
                                    font-size: 1em;

                                    p {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .catalog-home .container {
                    .catalog-cards-grid {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }

    /*.cards {
        padding: 0 40px;
        grid-template-columns: repeat(1, 1fr);
    }*/
}

@media screen and (max-width: 576px) {
    .principal {
        position: relative;

        .home { 
            /*.video-container {
                height: 224px;
            }*/

            .links-main {
                .first {
                    padding-right: 0;
                }

                .second {
                    padding-top: 10px;
                    width: 100%;
                }
            }

            .header-web .header-info .right {
                .right-right .exchange-rate {
                    padding: 0 16px;

                    label {
                        cursor: pointer;
                        font-size: 0.9em;
                    }
                }

                #language {
                    font-size: 0.9em;
                    padding-left: 0;
                }
            }

            .invert {
                .card-40-320 , .card-60-320 {
                    height: 245px;
                }
            }
    
            .third {
                .card-100 {                   
                    height: 245px;                 
                }
            }
            .card-category {
                flex-direction: column;
    
                .card-60, .card-60-320 {
                    width: 100%;             
                    margin: 0;
                    margin-bottom: $marginNormal;
                }
                
                .card-40, .card-40-320 {                  
                    width: 100%;             
                    margin: 0;
                  
                }
            }

            .categories-menu {
                .container {
                    width: 100%;
                }

                .menu-cat h4 {
                    font-size: 0.9rem;
                }

                /*.menu-cat {
                    .icon {
                        width: 100%;
                        display: block;
                    }
                }*/

                .none {
                    a {
                        display: none;
                    }

                    .icon {
                        width: 100%;
                        display: block;
                    }
                }

                .responsive {
                    position: relative;
                    display: block;

                    a {
                        display: block;
                        text-align: left;
                        padding: 12px 32px;
                    }

                    .icon {
                        width: 100%;
                        display: block;
                        text-align: center;
                    }
                }
            }

            .offers-gral {
                .best-offers {
                    .container .packs {
                        //height: 195px;

                        .item-promotion .rd-parallax-layer .bg-overlay-darker .top-text {
                            padding: 20px 20px 0 0;

                            .btn-reservation {
                                width: 15%;
                                font-size: 1em;

                                p {
                                    display: none;
                                }
                            }
                        }

                        .item-promotion img {
                            height: 170px !important;
                        }
                    }
                }

                .catalog-home .container {
                    .catalog-cards-grid {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
        }

        .chat {
            .chat-scd {
                bottom: 80px;
                padding-right: 25px;

                .chat-btn i {
                    font-size: 25px;
                }

                .chat-popup, .conversation-popup {
                    .popup {
                        padding: 0 15px;

                        .form-container {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.space-8 {
    padding-left: 8px;
}