.about-us {
    background-color: $white;
    img {
        background-size: cover;
    }

    .content {
        .four-imgs img {
            width: 100%;
        }

        .title h1 {
            color: $blue;
        }

        .subtitle h2 {
            color: $green;
        }

        .left {
            padding: 0 0 0 50px;
        }

        .left p {
            font-size: 20px;
            line-height: 25px;
        }

        .right {
            .question {
                padding: 25px 0;
                //background-color: bisque;
                border-bottom: 1px solid gray;
                cursor: pointer;
                h3:hover {
                    color: $yellow;
                }
            }
        }
    }


    .principal {
        height: 380px;
        background-size: cover;
        background-position: center;
        scroll-snap-align: center;
        background-attachment: fixed;
        background-repeat: no-repeat;

        .overlay {
           // background-color: #00a4b987;
           //background-color: #0963bf8f;
           background-color: #2384dbb0;
        }

        .tittle-principal {
            color: $white;
            font-size: 2.5rem;
        }

        .info-principal {
            width: 60%;
            p {
                font-size: 1.4rem;
                color: $white;
                line-height: 30px;
            }
        }

    }

    .clients {
        background-color: $white;
        padding: $paddingHuge;
    }

    .offer {
        background-color: $white;
        padding: 32px 0;

        .info-offer {
            width: 70%;
            p {
                font-size: 1.4rem;
                color: #0c63ba;
                line-height: 30px;
                font-weight: 500;
            }
        }

        .first-element {
            //padding-bottom: 24px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1.6rem;
        }
    }

    .culture {
        .first-text {
            width: 77%;
        }

        .img-culture img {
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
            border-radius: 4px;
        }

        .second-text {
            width: 76%;
        }

        p {
            font-size: 1.4rem;
            line-height: 30px;
            color: #0c63ba;
            font-weight: 500;
        }
    }

    .reserve {
        height: 380px;
        background-size: cover;
        background-position: center;
        scroll-snap-align: center;
        background-attachment: fixed;
        background-repeat: no-repeat;

        .overlay {
           // background-color: #00a4b987;
           background-color: #0963bf8f;
        }

        .tittle-principal {
            color: $white;
            font-size: 2.5rem;
        }

        img {
            width: 70%;
            border: 3px solid white;
        }

        .info-principal {
            padding-top: 24px;
            width: 100%;
            
            p {
                font-size: 1.6rem;
                color: $white;
                line-height: 30px;
            }
        }
    }

    .security {
        background-color: $white;
        padding: 32px 0;

        .img-openpay, .img-https {
            width: 40%;
        }

        .img-security {
            width: 20%;
        }

        .img-gob {
            width: 30%;
        }

        .info-offer {
            width: 77%;
            p {
                font-size: 1.4rem;
                color: #0c63ba;
                line-height: 30px;
                font-weight: 500;
            }
        }
    }

    .ubication {
        height: 450px;
        background-size: cover;
        background-position: center;
        scroll-snap-align: center;
        background-attachment: fixed;
        background-repeat: no-repeat;

        .overlay {
           // background-color: #00a4b987;
           background-color: #0963bf8f;
        }

        .tittle-principal {
            color: $white;
            font-size: 1.8rem;
        }

        img {
            //width: 75%;
            width: 85%;
            border: 3px solid white;
        }

        .info-principal {
            width: 100%;
            p {
                font-size: 1.3rem;
                color: $white;
                line-height: 25px;
            }
        }
        .row-reverse {
            flex-direction: row-reverse;
        }
    }
}

@media screen and (max-width: 992px) {
    .about-us {
        .reserve {
            img {
                width: 85%;
            }

            .info-principal {
                padding-top: 0;
            }
        }

        .principal {
            .info-principal {
                width: 80%;
            }
        }

        .offer {
            .info-offer {
                width: 80%;
            }

            .first-element {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .about-us {
        .reserve {
            height: auto;

            img {
                width: 65%;
            }

            .row-responsive {
                flex-direction: column-reverse !important;
            }

            .imagen {
                padding-top: 30px;
            }

            .info-principal {
                padding-top: 24px;
                padding-bottom: 30px;
                width: 92%;
            }
        }

        .principal {
            .info-principal {
                width: 95%;
            }

            .tittle-principal {
                font-size: 2.4rem;
            }
        } 

        .offer .info-offer,
        .security .info-offer {
            width: 90%;
        }

        .offer {
            padding: 0;

            .first-element, .second-element {
                padding-bottom: 0;
            }
        }

        .ubication {
            height: auto;

            .row-reverse {
                flex-direction: inherit;
            }

            .tittle-principal {
                padding-top: 30px;
            }

            .info-principal {
                padding-bottom: 25px;
            }

            .imagen {
                padding-bottom: 30px;
            }

            img {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .about-us {
        .reserve {
            height: auto;

            .row-responsive {
                flex-direction: column-reverse !important;
            }

            .imagen {
                padding-top: 30px;
            }

            .info-principal {
                padding-bottom: 30px;
                padding-top: 24px;
            }
        }
                
        .principal {
            .info-principal {
                width: 95%;
            }

            .tittle-principal {
                font-size: 2.2rem;
            }
        } 

        .offer .info-offer,
        .security .info-offer {
            width: 90%;
        }

        .security {
            padding-bottom: 0;
        }

        .offer {
            padding: 0;

            .first-element {
                padding-bottom: 0;
                grid-template-columns: repeat(1, 1fr);
                width: 85%;
            }
        }

        .clients {
            h1 {
                font-size: 1.4rem;
            }
        }

        .ubication {
            height: auto;

            .row-reverse {
                flex-direction: inherit;
            }

            .tittle-principal {
                padding-top: 30px;
            }

            .info-principal {
                padding-bottom: 25px;
            }

            .imagen {
                padding-bottom: 30px;
            }
        }
    }
}