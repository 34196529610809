
footer  {
    padding: $paddingLarge 0;
    background-color: $white;    

    .left {
        width: 50%;

        .image {
            height: 90px;
        }
    }

    .left img {
        height: 75px;
    }

    .social .icon {
        padding: 0 $paddingMedium;
    }

    .right {
        width: 50%;
    }

    .links .title {
        h3 { color: #555555; }
    }

    .links .link, .links .link-right {
        padding: 6px 0;
        a {
            color: #9a9a9a;
            font-size: 0.95rem;
        }
    }
}


@media screen and (max-width: 992px) { 
    footer  {
        .row-responsive {
            flex-direction: column;
        }

        .right {
            width: 100%;
            margin-top: 16px;
        }
        .left {
            width: 100%;
            align-items: center;

            .social {
                width: 50%;

                .icon {
                    i {
                        font-size: $fontLarge;
                    }
                }
            }

            .image {
                width: 50%;
                height: 45px;
                justify-content: center;

                img {
                    height: 45px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    footer {
        .links .link {
            width: 50%;
        }

        .left img {
            height: 60px;
        }
    }
}