.catalog-general {
    font-family: $Roboto;
    background-color: $white;

    .fb_reset {
        display: none;
    }

    .catalog {
        width: 100%;
        background-size: cover;
        background-repeat-y: no-repeat;
        background-position: bottom;

        .catalog-sections {
            .filters-mobile {
                display: none;
            }

            .filters {
                box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                width: 30%;
                padding: 34px 30px 23px;
                background: #fff;
                height: fit-content;

                .search-filter, .price-range {
                    border-bottom: 1px solid gray;
                    padding-bottom: 25px;
                }

                .price-range {
                    .range {
                        width: 100%;
                    }
                }

                .categories {
                    .category .left {
                        width: 20%;
                    }
                }
            }

            .catalog-cards {
                padding-left: 30px;
       
                .ribbon-wrapper-red {
                    width: 120px;
                    height: 95px;
                    overflow: hidden;
                    position: absolute;
                    top: -4px;
                    right: -4px;
                }
                
                .ribbon-red {
                    font: bold 15px Sans-Serif;
                    color: #fff;
                    text-align: center;
                    text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    position: relative;
                    padding: 5px 33px;
                    left: 22px;
                    top: 20px;
                    width: 127px;
                    height: 29px;
                    background-color: #ea181e;
                    background-image: linear-gradient(to top, rgb(158, 6, 6) , red,);
                    background-image: -webkit-linear-gradient(top, #ea5b18, #b90005);
                    background-image: -moz-linear-gradient(top, #BFDC7A, #8EBF45);
                    background-image: -ms-linear-gradient(top, #BFDC7A, #8EBF45);
                    background-image: -o-linear-gradient(top, #BFDC7A, #8EBF45);
                    color: #fff;
                    -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
                    -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
                    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
                }
                
                .ribbon-red:before,
                .ribbon-red:after{
                    content: "";
                    border-top: 3px solid #b90005;   
                    border-left: 3px solid transparent;
                    border-right: 3px solid transparent;
                    position:absolute;
                    bottom: -3px;
                }
                
                .ribbon-red:before{
                    left: 0;
                }
                
                .ribbon-red:after{
                    right: 0;
                }

                .range {

                    .sort-by p, .view p{
                        font-size: 0.8em;
                        padding-right: 5px;
                    }

                    #filtrar {
                        height: 30px;
                        width: 30%;
                        border-radius: 15px;
                        padding-left: 15px;
                        margin-left: 10px;
                        box-shadow: 0 1.5px 3px 0px #909090;
                    }

                    .btn-Listview{
                        width: 7%;
                        padding: 4px;
                        border: 1px solid rgb(155, 155, 155);
                        border-radius: 2px;
                        margin: 4px;
                        background-color: rgb(155, 155, 155);

                        &:hover {
                            border: 1px solid $green;
                            border-radius: 2px;
                            margin: 4px;
                            background-color: $green;
                        }
                    }

                    .btn-Listview i {
                        color: white;
                    }
                }

                .catalog-cards-grid {
                    display: grid;
                    padding-top: 1.7rem;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 1.2rem;
                }

                .catalog-cards-list {
                    padding-top: 1.7rem;
                    display: grid;
                    grid-gap: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .catalog-general {
        .catalog {
            .catalog-sections{
                .filters {
                    padding: 25px;
                    width: 40%;

                    h4 {
                        font-size: 1rem;
                    }
                }

                .catalog-cards {
                    padding-left: 20px;

                    .range {
                        #filtrar {
                            width: 52%;
                        }

                        .btn-Listview{
                            width: 12%;
                        }
                    }

                    .catalog-cards-grid {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .catalog-general {
        .white-space-64 {
            height: 48px;
        }

        .catalog {
            .catalog-sections{
                align-items: center;

                .filters {
                    width: 85%;
                }

                .catalog-cards {
                    padding: 0 15px;

                    .range {
                        padding-top: 30px;

                        #filtrar {
                            width: 48%;
                        }

                        .btn-Listview{
                            width: 11%;
                        }
                    }

                    .catalog-cards-grid {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .catalog-general {
        .white-space-64 {
            height: 48px;
        }

        .catalog {
            .catalog-sections{
                align-items: center;

                .filters-mobile {
                    display: block;
                    width: 80%;

                    .inputs {
                        position: relative;

                        .btn-filter {
                            font-size: 0.9em;
                            background-color: transparent;
                            width: auto;
                            position: absolute;
                            right: 0;
                            color: $darkGray;

                            &:hover {
                                color: $red;
                            }
                        }
                    }
                }

                .filters {
                    width: 90%;
                    display: none;
                }

                .catalog-cards {
                    padding: 0 25px;

                    .range {
                        padding-top: 30px;

                        .sort-by {
                            justify-content: center;
                        }

                        #filtrar {
                            width: 45%;
                        }

                        .view {
                            display: none;
                        }
                    }

                    .catalog-cards-grid {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
        }
    }
}