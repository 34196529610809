.banner-content {
    width: 55%;
    background: transparent;
    box-shadow: none;

    .modal-body-banner {
        position: relative;
        height: 450px;

        .close-banner {
            position: absolute;
            right: 0;
            top: 5px;
            width: 30px;
            height: 30px;
            display: block;
            overflow: hidden;
        }

        .close-banner i {
            color: white;
            font-size: 1.6em;
            cursor: pointer;
        }

        .info-product {
            position: relative;
			margin-right: 36px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			border-radius: 4px;
			box-shadow: 0px 0px 20px 6px #9E9E9E;

            .img-banner {
                position: relative;
                box-shadow: 0px 0px 20px 6px #9E9E9E;
                border-radius: 4px;
            }
        }

        .banner-b {
            position: absolute;
            /*bottom: 45px;
            height: 100vh;*/
        }

        .text-banner {
            /*margin-bottom: 210px;*/
            height: 46vh;
        }

        .text-banner h1 {
            font-size: 2em !important;
            font-family: 'Della Respira';
        }
    }
}

@media screen and (min-width: 1325px) {
    .banner-content .modal-body-banner .close-banner {
        //width: 33px !important;
    }
}

@media screen and (max-width: 1200px) {
    .banner-content {
        width: 60% !important;
    }

    .modal-body-banner {
        height: 400px !important;
    }

    /*.banner-b {
        bottom: 40px !important;
    }*/

    .btn-banner {
        font-size: 1.2em;
    }

    .text-banner {
        /*margin-bottom: 210px;*/
        height: 37vh !important;
    }

    .text-banner h1 {
        font-size: 2em !important;
    }
}

@media screen and (max-width: 992px) {
    .banner-content {
        width: 75% !important;
    }

    .modal-body-banner {
        height: 395px !important;
    }

    /*.banner-b {
        bottom: 35px !important;
    }*/

    .btn-banner {
        font-size: 1.1em;
        padding: 8px 24px;
    }

    .text-banner {
        /*margin-bottom: 210px;*/
        height: 40vh !important;
    }

    .text-banner h1 {
        font-size: 1.8em !important;
    }
}

@media screen and (max-width: 768px) {
    .banner-content {
        width: 90% !important;
    }

    .modal-body-banner {
        /*height: 345px !important;*/
        height: 340px !important;
    }

    /*.banner-b {
        bottom: 30px !important;
    }*/

    .btn-banner {
        font-size: 1em;
        padding: 6px 24px;
    }

    .text-banner {
        /*margin-bottom: 210px;*/
        height: 36vh !important;
    }

    .text-banner h1 {
        font-size: 1.4em !important;
    } 
}

/*@media screen and (max-width: 670px) {
    .banner-content {
        width: 90% !important;
    }

    .modal-body-banner {
        height: 324px !important;
    }
}

@media screen and (max-width: 570px) {
    .modal-body-banner {
        height: 254px !important;
    }
}*/

@media screen and (max-width: 576px) {
    .banner-content {
        width: 90% !important;
    }

    .modal-body-banner {
        height: 210px !important;

        .info-product {
            margin-right: 0px !important;
        }

        .close-banner {
            position: relative !important;
            display: flex !important;
            top: 0 !important;

            i {
                font-size: 1em !important;
            }
        }
    }

    /*.banner-b {
        bottom: 30px !important;
    }*/

    .btn-banner {
        font-size: 0.8em;
        padding: 4px 12px;
    }

    .text-banner {
        /*margin-bottom: 210px;*/
        height: 22vh !important;
    }

    .text-banner h1 {
        font-size: 1.2em !important;
    } 
}