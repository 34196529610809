.payment {
    .payment-sections {
        background-color: #f7f7f7;
        border-radius: 4px;
        
        .payment-left {
            width: 60%;
            padding: 30px;
            //background-color: beige;

            .pay .paydata {
                .cardstype {
                    .creditcard {
                        width: 55%;
                    }

                    .debitcard {
                        border-left: 1px solid;
                        padding-left: 10px;
                    }
                    
                    .creditcard h4, .debitcard h4 {
                        font-size: 0.8em;
                    }
                }

                .input-card {
                    position: relative;
                }

                .input-card .fab {
                    position: absolute;
                    top: 5px;
                    right: 3%;
                    font-size: 18px;
                }

                .info-openpay {
                    .openpay {
                        border-right: 1px solid;
                    }

                    .openpay h4 {
                        font-size: 0.9em;
                    }

                    .security {
                        width: 48%;
                    }
                }

                .tyc {
                    p {
                        padding-left: 5px;
                    }
                }
            }
        }

        .payment-right {
            padding: 30px;
            //background-color: #2a545614;

            .payment-detail {
                overflow: auto;
            }

            .payment-detail-title {
                border-bottom: 1px solid #7da2ab;
                padding-bottom: 16px;
            }

            .payment-detail-cart {
                /*height: 220px;
                overflow: auto;*/
                .exrate {
                    color: $pink;
                    font-size: 0.8rem;
                }

                .price {
                    width: 40%;
                    padding-right: 15px;
                }
            }

            .payment-detail-total {
                .total-price {
                    width: 60%;

                    .exrate {
                        font-size: 1rem;
                        color: $pink;
                    }
                }

                .total-price h3 {
                    color: $green;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
	.payment { 
        .payment-sections {
            .payment-left {
                width: 100%;
                padding: 30px;
            }
        } 

        .payment-right {
            .payment-detail {
                .payment-detail-cart {
                    .price {
                        padding-top: 15px;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    /*.payment {
        .payment-sections {
            .payment-right {
                .payment-detail {
                    .payment-detail-cart {
                        .price {
                            padding-top: 15px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }*/
}